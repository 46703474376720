
 /*----------  
 	Google fonts  
 ----------*/
 

/* Global Body Style */
body{
	color: $white;
	background: $brand-secondary;
	font-family: $roboto;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}

/* Heading Fonts Size */
h1,h2,h3{
	font-family: $playfair;
	color: $primary-color;
}
h4 {
	font-family: $roboto;
}
h1{
	font-size: 65px;
}
h2{
	font-size: 40px;
}
h3{
	font-size: 22px;
}
h4{
	font-size: 18px;
}
p {
	font-family: $roboto;
}