/**********************************************
 Contact Action
***********************************************/
.contact-call-to-action{
	position: relative;
	background: #171717;
	width: 100%;
	#map{
		height: 410px;
	}
	.block {
		h2 {
			margin-top: 0;
			font-size: 30px;
		}
		p {
			font-size: 15px;
			width: 40%;
		}
		.btn-main {
			margin-top: 10px;
		}
	}
}

.shadow-block{
    position: absolute;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 30px 0;
    background: linear-gradient(90deg, black 30%, rgba(0, 0, 0, 0.51) 47%, rgba(192, 192, 192, 0.15) 61%);
}
