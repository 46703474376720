/**
 * ---------------------------------------------------------------------------
 * . Header Styles
 * ---------------------------------------------------------------------------
 */

.hero-area {
	height:100vh;
	background-size:cover;
	display: flex;
	justify-content:center;
	align-items:center;
	text-align: center;
	h1 {
		color: #fff;
	}
}

.hero-video {
	height:100vh;
	display: flex;
	justify-content:center;
	align-items:center;
	text-align: center;
	&:before {
		content: '';
		background:rgba(0, 0, 0, 0.67);
		position: absolute;
		right:0;
		left:0;
		bottom:0;
		top:0;
	}
	h1 {
		color: $primary-color;
	}
}